.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

.container {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
}

.buttonStart {
    margin: 5px!important;
    background-color: green!important;
}

.buttonStop {
    margin: 5px!important;
    background-color: red!important;
}

.buttonReset {
    margin: 5px!important;
    background-color: blue!important;
}
.list{
    padding: 0px;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

