.container {
    display: flex;
    flex-direction: column;
    width: 70%;
    margin: 0 auto;
}

.buttonStart {
    margin: 5px!important;
    background-color: green!important;
}

.buttonStop {
    margin: 5px!important;
    background-color: red!important;
}

.buttonReset {
    margin: 5px!important;
    background-color: blue!important;
}
.list{
    padding: 0px;
}